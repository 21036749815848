const data = [
  {
    id: 0,
    image: "/img/Autumn/image1.jpeg",
    product_type: "Autumn",
    product_name: "8寸圓形鏤空",
    size: "直徑20CM",
  },
  {
    id: 1,
    image: "/img/Autumn/image1.jpeg",
    product_type: "Autumn",
    product_name: "10寸圓形鏤空",
    size: "直徑25CM",
  },
  {
    id: 2,
    image: "/img/Autumn/image1.jpeg",
    product_type: "Autumn",
    product_name: "12寸圓形鏤空",
    size: "直徑30CM",
  },
  {
    id: 3,
    image: "/img/Autumn/image2.jpeg",
    product_type: "Autumn",
    product_name: "6寸冬瓜鏤空",
    size: "直徑15CM",
  },
  {
    id: 4,
    image: "/img/Autumn/image3.jpeg",
    product_type: "Autumn",
    product_name: "8寸冬瓜鏤空",
    size: "直徑20CM",
  },
  {
    id: 5,
    image: "/img/Autumn/image4.jpeg",
    product_type: "Autumn",
    product_name: "六角鏤空",
    size: "直徑15CM",
  },
  {
    id: 6,
    image: "/img/Autumn/image5.jpeg",
    product_type: "Autumn",
    product_name: "熱氣球鏤空",
    size: "直徑20CM",
  },
  {
    id: 7,
    image: "/img/Autumn/image6.jpeg",
    product_type: "Autumn",
    product_name: "熱氣球花草",
    size: "直徑20CM",
  },
  {
    id: 8,
    image: "/img/Autumn/image7.jpeg",
    product_type: "Autumn",
    product_name: "8寸冬瓜花草",
    size: "直徑20CM",
  },
  {
    id: 9,
    image: "/img/Autumn/image8.jpeg",
    product_type: "Autumn",
    product_name: "8寸圓形花草",
    size: "直徑20CM",
  },
  {
    id: 10,
    image: "/img/Autumn/image9.jpeg",
    product_type: "Autumn",
    product_name: "12寸嫦娥",
    size: "直徑30CM",
  },
  {
    id: 11,
    image: "/img/Autumn/image9.jpeg",
    product_type: "Autumn",
    product_name: "8寸嫦娥",
    size: "直徑20CM",
  },
  {
    id: 12,
    image: "/img/Autumn/image10.jpeg",
    product_type: "Autumn",
    product_name: "腰鼓形花草",
    size: "15CM",
  },
  {
    id: 13,
    image: "/img/Autumn/image65.jpeg",
    product_type: "Autumn",
    product_name: "鑽石形花草",
    size: "17CM",
  },
  {
    id: 14,
    image: "/img/Autumn/image11.jpeg",
    product_type: "Autumn",
    product_name: "12寸熱氣球",
    size: "直徑30CM",
  },
  {
    id: 15,
    image: "/img/Autumn/image11.jpeg",
    product_type: "Autumn",
    product_name: "10寸熱氣球",
    size: "直徑25CM",
  },
  {
    id: 16,
    image: "/img/Autumn/image12.jpeg",
    product_type: "Autumn",
    product_name: "8寸紙燈10寸紙燈,12寸紙燈,14寸紙燈",
    size: "直徑20CM,直徑25CM,直徑30CM,直徑35CM",
  },
  {
    id: 17,
    image: "/img/Autumn/image3.jpeg",
    product_type: "Autumn",
    product_name: "4寸兔子",
    size: "直徑10CM",
  },
  {
    id: 18,
    image: "/img/Autumn/image3.jpeg",
    product_type: "Autumn",
    product_name: "3寸兔子",
    size: "直徑7CM",
  },
  {
    id: 19,
    image: "/img/Autumn/image14.jpeg",
    product_type: "Autumn",
    product_name: "6寸櫻花布燈籠",
    size: "直徑15CM",
  },
  {
    id: 20,
    image: "/img/Autumn/image15.jpeg",
    product_type: "Autumn",
    product_name: "4寸櫻花布燈籠",
    size: "直徑10CM",
  },
  {
    id: 21,
    image: "/img/Autumn/image15.jpeg",
    product_type: "Autumn",
    product_name: "3寸櫻花布燈籠",
    size: "直徑7CM",
  },
  {
    id: 22,
    image: "/img/Autumn/image16.jpeg",
    product_type: "Autumn",
    product_name: "荷花燈籠",
    size: "燈籠直徑7CM",
  },
  {
    id: 23,
    image: "/img/Autumn/image17.jpeg",
    product_type: "Autumn",
    product_name: "DIY燈籠",
    size: "直徑20CM",
  },
  {
    id: 24,
    image: "/img/Autumn/image18.jpeg",
    product_type: "Autumn",
    product_name: "DIY燈籠",
    size: "直徑20CM",
  },
  {
    id: 25,
    image: "/img/Autumn/image19.jpeg",
    product_type: "Autumn",
    product_name: "圓形紙燈籠",
    size: "直徑17CM",
  },
  {
    id: 26,
    image: "/img/Autumn/image20.jpeg",
    product_type: "Autumn",
    product_name: "方形紙燈籠",
    size: "直徑17CM",
  },
  {
    id: 27,
    image: "/img/Autumn/image21.jpeg",
    product_type: "Autumn",
    product_name: "六角紙燈籠",
    size: "直徑17CM",
  },
  {
    id: 28,
    image: "/img/Autumn/image22.jpeg",
    product_type: "Autumn",
    product_name: "正版走馬燈",
    size: "直徑17CM",
  },
  {
    id: 29,
    image: "/img/Autumn/image23.jpeg",
    product_type: "Autumn",
    product_name: "A款方形紙燈",
    size: "直徑20CM",
  },
  {
    id: 30,
    image: "/img/Autumn/image24.jpeg",
    product_type: "Autumn",
    product_name: "六角形紙方燈籠",
    size: "直徑15CM",
  },
  {
    id: 31,
    image: "/img/Autumn/image25.jpeg",
    product_type: "Autumn",
    product_name: "兔子DIY",
    size: "直徑20CM",
  },
  {
    id: 32,
    image: "/img/Autumn/image26.jpeg",
    product_type: "Autumn",
    product_name: "醒獅DIY",
    size: "直徑20CM",
  },
  {
    id: 33,
    image: "/img/Autumn/image27.jpeg",
    product_type: "Autumn",
    product_name: "DIY兔子",
    size: "直徑22CM",
  },
  {
    id: 34,
    image: "/img/Autumn/image28.jpeg",
    product_type: "Autumn",
    product_name: "DIY兔子",
    size: "直徑25CM",
  },
  {
    id: 35,
    image: "/img/Autumn/image29.jpeg",
    product_type: "Autumn",
    product_name: "EVA材質荷花燈",
    size: "直徑17CM",
  },
  {
    id: 36,
    image: "/img/Autumn/image30.jpeg",
    product_type: "Autumn",
    product_name: "3寸淨色串燈,3寸櫻花串燈,4寸櫻花串燈",
    size: "直徑7cm,直徑7cm,直徑10CM",
  },
  {
    id: 37,
    image: "/img/Autumn/image31.jpeg",
    product_type: "Autumn",
    product_name: "非物質文化遺產鐵架兔子",
    size: "10CM,15CM,20CM",
  },
  {
    id: 38,
    image: "/img/Autumn/image32.jpeg",
    product_type: "Autumn",
    product_name: "非物質文化遺產鐵架金魚",
    size: "15CM,20CM",
  },
  {
    id: 39,
    image: "/img/Autumn/image33.jpeg",
    product_type: "Autumn",
    product_name: "打包帶支架",
    size: "15CM,18CM",
  },
  {
    id: 40,
    image: "/img/Autumn/image34.jpeg",
    product_type: "Autumn",
    product_name: "圓球兔子燈有音樂",
    size: "20CM左右",
  },
  {
    id: 41,
    image: "/img/Autumn/image35.jpeg",
    product_type: "Autumn",
    product_name: "圓球卡通燈籠有音樂",
    size: "15CM",
  },
  {
    id: 42,
    image: "/img/Autumn/image36.jpeg",
    product_type: "Autumn",
    product_name: "卡通燈籠有音樂",
    size: "17CM左右",
  },
  {
    id: 43,
    image: "/img/Autumn/image37.jpeg",
    product_type: "Autumn",
    product_name: "無紡布DIY",
    size: "25CM",
  },
  {
    id: 44,
    image: "/img/Autumn/image38.jpeg",
    product_type: "Autumn",
    product_name: "無紡布卡通燈籠",
    size: "配無音樂燈棒9.5元配音樂燈棒10.5元",
  },
  {
    id: 45,
    image: "/img/Autumn/image39.jpeg",
    product_type: "Autumn",
    product_name: "B款繡球燈",
    size: "直徑19CM",
  },
  {
    id: 46,
    image: "/img/Autumn/image40.jpeg",
    product_type: "Autumn",
    product_name: "蓮花燈",
    size: "13CM",
  },
  {
    id: 47,
    image: "/img/Autumn/image41.jpeg",
    product_type: "Autumn",
    product_name: "六角燈有6個圖案",
    size: "18CM",
  },
  {
    id: 48,
    image: "/img/Autumn/image42.jpeg",
    product_type: "Autumn",
    product_name: "六角燈有6個圖案",
    size: "13CM",
  },
  {
    id: 49,
    image: "/img/Autumn/image43.jpeg",
    product_type: "Autumn",
    product_name: "無紡布宮燈",
    size: "直徑18CM",
  },
  {
    id: 50,
    image: "/img/Autumn/image44.jpeg",
    product_type: "Autumn",
    product_name: "花邊無紡布",
    size: "直徑15CM",
  },
  {
    id: 51,
    image: "/img/Autumn/image45.jpeg",
    product_type: "Autumn",
    product_name: "古風燈籠",
    size: "直徑18CM",
  },
  {
    id: 52,
    image: "/img/Autumn/image46.jpeg",
    product_type: "Autumn",
    product_name: "半成品古風燈籠",
    size: "直徑18CM",
  },
  {
    id: 53,
    image: "/img/Autumn/image47.jpeg",
    product_type: "Autumn",
    product_name: "半成品古風燈籠",
    size: "直徑18CM",
  },
  {
    id: 54,
    image: "/img/Autumn/image48.jpeg",
    product_type: "Autumn",
    product_name: "花邊無紡布",
    size: "直徑15CM",
  },
  {
    id: 55,
    image: "/img/Autumn/image49.jpeg",
    product_type: "Autumn",
    product_name: "花邊走馬燈",
    size: "直徑18CM",
  },
  {
    id: 56,
    image: "/img/Autumn/image50.jpeg",
    product_type: "Autumn",
    product_name: "實心桿",
    size: "長度22CM",
  },
  {
    id: 57,
    image: "/img/Autumn/image51.jpeg",
    product_type: "Autumn",
    product_name: "木桿",
    size: "長度28CM",
  },
  {
    id: 58,
    image: "/img/Autumn/image52.jpeg",
    product_type: "Autumn",
    product_name: "A款方形電子燈燈",
    size: "有彩色有暖色",
  },
  {
    id: 59,
    image: "/img/Autumn/image53.jpeg",
    product_type: "Autumn",
    product_name: "B款電子燈",
    size: "有彩色有暖色",
  },
  {
    id: 60,
    image: "/img/Autumn/image54.jpeg",
    product_type: "Autumn",
    product_name: "半成品古風燈籠",
    size: "直徑18CM",
  },
  {
    id: 61,
    image: "/img/Autumn/image55.jpeg",
    product_type: "Autumn",
    product_name: "瓔珞圈古風燈籠",
    size: "直徑18CM",
  },
  {
    id: 62,
    image: "/img/Autumn/image56.jpeg",
    product_type: "Autumn",
    product_name: "DIY燈籠",
    size: "直徑20CM",
  },
];
export default data;
