const data = [
  {
    id: 0,
    product_type: "New-Year",
    product_name: "New-Year1",
    size: "20 x 60",
    price: 888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 1,
    product_type: "New-Year",
    product_name: "New-Year2",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 2,
    product_type: "New-Year",
    product_name: "New-Year3",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/New-Year/white-carrot.jpg",
  },
  {
    id: 3,
    product_type: "Christmas",
    product_name: "Christmas1",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/mango.jpg",
  },
  {
    id: 4,
    product_type: "Christmas",
    product_name: "Christmas2",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 5,
    product_type: "Christmas",
    product_name: "Christmas3",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 6,
    product_type: "Christmas",
    product_name: "test5",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 7,
    product_type: "Christmas",
    product_name: "test5",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 8,
    product_type: "Christmas",
    product_name: "test5",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 9,
    product_type: "Christmas",
    product_name: "test5",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 10,
    product_type: "Christmas",
    product_name: "test5",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 11,
    product_type: "Christmas",
    product_name: "test5",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
  {
    id: 12,
    product_type: "Christmas",
    product_name: "test5",
    size: "20 x 60",
    price: 8888,
    image:
      "https://raw.githubusercontent.com/eddy1129/fushing/master/public/img/Christmas/orange.jpg",
  },
];
export default data;
