const GlowStick = [
  {
    id: 0,
    image: "/img/GlowStick/image1.png",
    product_type: "GlowStick",
    product_name: "8寸圓形鏤空",
    size: "直徑20CM",
  },
  {
    id: 1,
    image: "/img/GlowStick/image1.png",
    product_type: "GlowStick",
    product_name: "10寸圓形鏤空",
    size: "直徑25CM",
  },
  {
    id: 2,
    image: "/img/GlowStick/image1.png",
    product_type: "GlowStick",
    product_name: "12寸圓形鏤空",
    size: "直徑30CM",
  },
  {
    id: 3,
    image: "/img/GlowStick/image2.png",
    product_type: "GlowStick",
    product_name: "6寸冬瓜鏤空",
    size: "直徑15CM",
  },
  {
    id: 4,
    image: "/img/GlowStick/image3.png",
    product_type: "GlowStick",
    product_name: "8寸冬瓜鏤空",
    size: "直徑20CM",
  },
  {
    id: 5,
    image: "/img/GlowStick/image4.png",
    product_type: "GlowStick",
    product_name: "六角鏤空",
    size: "直徑15CM",
  },
  {
    id: 6,
    image: "/img/GlowStick/image5.png",
    product_type: "GlowStick",
    product_name: "熱氣球鏤空",
    size: "直徑20CM",
  },
  {
    id: 7,
    image: "/img/GlowStick/image6.png",
    product_type: "GlowStick",
    product_name: "熱氣球花草",
    size: "直徑20CM",
  },
  {
    id: 8,
    image: "/img/GlowStick/image7.png",
    product_type: "GlowStick",
    product_name: "8寸冬瓜花草",
    size: "直徑20CM",
  },
  {
    id: 9,
    image: "/img/GlowStick/image8.png",
    product_type: "GlowStick",
    product_name: "8寸圓形花草",
    size: "直徑20CM",
  },
  {
    id: 10,
    image: "/img/GlowStick/image9.png",
    product_type: "GlowStick",
    product_name: "12寸嫦娥",
    size: "直徑30CM",
  },
  {
    id: 11,
    image: "/img/GlowStick/image9.png",
    product_type: "GlowStick",
    product_name: "8寸嫦娥",
    size: "直徑20CM",
  },
  {
    id: 12,
    image: "/img/GlowStick/image10.png",
    product_type: "GlowStick",
    product_name: "腰鼓形花草",
    size: "15CM",
  },
  {
    id: 13,
    image: "/img/GlowStick/image11.png",
    product_type: "GlowStick",
    product_name: "鑽石形花草",
    size: "17CM",
  },
  {
    id: 14,
    image: "/img/GlowStick/image11.png",
    product_type: "GlowStick",
    product_name: "12寸熱氣球",
    size: "直徑30CM",
  },
  {
    id: 15,
    image: "/img/GlowStick/image11.png",
    product_type: "GlowStick",
    product_name: "10寸熱氣球",
    size: "直徑25CM",
  },
  {
    id: 16,
    image: "/img/GlowStick/image12.png",
    product_type: "GlowStick",
    product_name: "8寸紙燈10寸紙燈,12寸紙燈,14寸紙燈",
    size: "直徑20CM,直徑25CM,直徑30CM,直徑35CM",
  },
  {
    id: 17,
    image: "/img/GlowStick/image3.png",
    product_type: "GlowStick",
    product_name: "4寸兔子",
    size: "直徑10CM",
  },
  {
    id: 18,
    image: "/img/GlowStick/image3.png",
    product_type: "GlowStick",
    product_name: "3寸兔子",
    size: "直徑7CM",
  },
  {
    id: 19,
    image: "/img/GlowStick/image14.png",
    product_type: "GlowStick",
    product_name: "6寸櫻花布燈籠",
    size: "直徑15CM",
  },
  {
    id: 20,
    image: "/img/GlowStick/image15.png",
    product_type: "GlowStick",
    product_name: "4寸櫻花布燈籠",
    size: "直徑10CM",
  },
  {
    id: 21,
    image: "/img/GlowStick/image15.png",
    product_type: "GlowStick",
    product_name: "3寸櫻花布燈籠",
    size: "直徑7CM",
  },
  {
    id: 22,
    image: "/img/GlowStick/image16.png",
    product_type: "GlowStick",
    product_name: "荷花燈籠",
    size: "燈籠直徑7CM",
  },
  {
    id: 23,
    image: "/img/GlowStick/image17.png",
    product_type: "GlowStick",
    product_name: "DIY燈籠",
    size: "直徑20CM",
  },
  {
    id: 24,
    image: "/img/GlowStick/image18.png",
    product_type: "GlowStick",
    product_name: "DIY燈籠",
    size: "直徑20CM",
  },
  {
    id: 25,
    image: "/img/GlowStick/image19.png",
    product_type: "GlowStick",
    product_name: "圓形紙燈籠",
    size: "直徑17CM",
  },
  {
    id: 26,
    image: "/img/GlowStick/image20.png",
    product_type: "GlowStick",
    product_name: "方形紙燈籠",
    size: "直徑17CM",
  },
  {
    id: 27,
    image: "/img/GlowStick/image21.png",
    product_type: "GlowStick",
    product_name: "六角紙燈籠",
    size: "直徑17CM",
  },
  {
    id: 28,
    image: "/img/GlowStick/image22.png",
    product_type: "GlowStick",
    product_name: "正版走馬燈",
    size: "直徑17CM",
  },
  {
    id: 29,
    image: "/img/GlowStick/image23.png",
    product_type: "GlowStick",
    product_name: "A款方形紙燈",
    size: "直徑20CM",
  },
  {
    id: 30,
    image: "/img/GlowStick/image24.png",
    product_type: "GlowStick",
    product_name: "六角形紙方燈籠",
    size: "直徑15CM",
  },
];
export default GlowStick;
